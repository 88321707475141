import * as React from "react";
import JobForm from "../components/JobForm";

interface IProps {}

const ApplyContainer = (props: IProps) => {
  return (
    <main className="max-w-7xl mx-auto px-8">
      <div className="py-[50px]">
        <h1 className="text-[21px] lg:text-[38px] font-bold text-center uppercase mb-10">
          Өртөөгөөр дамжуулж үйлчилгээ үзүүлэх ажилтны анкет
        </h1>

        <p className="text-[21px] text-center w-full lg:w-3/4 mx-auto">
          Хэрвээ Та “Өртөө”-гөөр дамжуулж өөрийн чадах ямар нэг ажлаар бусдад
          төлбөртэй үйлчлэхийг хүсэж байвал дараах анкетыг үнэн зөв бөглөнө үү
          (өөрт хамааралгүй хэсгийг хоосон үлдээж болно)? <br /> <br />
          Таны мэдээлэл “Хүний хувийн мэдээлэл хамгаалах тухай” хуулийн дагуу
          хамгаалагдах болно.
        </p>
      </div>
      <div>
        <JobForm />
      </div>
    </main>
  );
};

export default ApplyContainer;
