import React, { useEffect, useRef, useState } from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormik,
} from "formik";
import * as Yup from "yup";
import apiContact from "../api/contact";
import {
  AiFillStar,
  AiOutlineCheck,
  AiOutlineLoading,
  AiOutlineStar,
} from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import apiWorker from "../api/cvs";
import moment from "moment";
import toast from "react-hot-toast";

const JobForm = () => {
  const [status, setStatus] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  console.log("data: ", data);

  const handleSubmit = async (values: any, form: any) => {
    setLoading(true);
    let tmp = { ...values };

    tmp.interested_in =
      tmp?.interested_in?.map((inte: any) => {
        const name = data?.find((d: any) => d.id == inte?.type)?.name || "";
        return { ...inte, name, type: inte?.type + '' };
      }) || [];

    tmp.birthday = moment(tmp?.birthday).format("YYYY-MM-DD");
    tmp.available_date = moment(tmp?.available_date).format("YYYY-MM-DD");
    tmp.agreement = "1";
    tmp.lib_image_id = "";

    tmp.language = (tmp.language || []).map((l: any) => ({
      ...l,
      rate: "" + l.rate,
    }));


    try {
      const res = await apiWorker.insert(tmp);
      if (res?.data?.error) {
        toast.error(
          "Уучлаарай мэдээллээ шалгаад дахин илгээнэ үү, Хүсэлт явуулах үед алдаа гарлаа."
        );
        form.setSubmitting(false);
      } else {
        toast.success(
          "Таны хүсэлт амжилттай илгээгдлээ, Бид таньтай холбогдох болно."
        );
        form.resetForm();
      }
    } catch (e) {
      toast.error(
        "Уучлаарай мэдээллээ шалгаад дахин илгээнэ үү, Хүсэлт явуулах үед алдаа гарлаа."
      );
      form.setSubmitting(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await apiWorker.findType();
        setData(res?.data?.worker_cv_type || []);
      } catch (e) {
        setData(null);
      }
    };
    fetch();
  }, []);

  return (
    <div className="relative">
      {loading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white/90 z-50 w-full h-full flex items-center justify-center">
          <AiOutlineLoading className="text-3xl animate-spin" />
        </div>
      )}
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          birthday: "",
          phone: "",
          gender: "",
          available_date: "",
          agreement: false,
          work_experience: "",
          language: [
            {
              lang: "English",
              rate: "0",
            },
          ],
          interested_in: [
            {
              type: "",
              time: "",
            },
          ],
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Мэйл алдаатай байна")
            .required("Бөглөх хэрэгтэй"),
          firstname: Yup.string().required("Бөглөх хэрэгтэй"),
          lastname: Yup.string().required("Бөглөх хэрэгтэй"),
          birthday: Yup.string().required("Бөглөх хэрэгтэй"),
          phone: Yup.string().required("Бөглөх хэрэгтэй"),
          gender: Yup.string().required("Бөглөх хэрэгтэй"),
          agreement: Yup.bool().oneOf(
            [true],
            "Та үйлчилгээний нөхцөлийг зөвшөөрөх хэрэгтэй."
          ),
          work_experience: Yup.string().required("Бөглөх хэрэгтэй"),
          available_date: Yup.string().required("Бөглөх хэрэгтэй"),
        })}
        onSubmit={(values, form) => {
          handleSubmit(values, form);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <h3 className="text-[21px] lg:text-[38px] font-bold my-10">
              Ерөнхий мэдээлэл
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
              <div className="w-full">
                <label className="mb-2 block">Таны овог:</label>
                <Field
                  type="text"
                  name="lastname"
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                  placeholder="Овог"
                />
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="lastname" component="div" />
                </div>
              </div>

              <div className="w-full">
                <label className="mb-2 block">Хүйс:</label>
                <Field
                  component="select"
                  id="gender"
                  name="gender"
                  multiple={false}
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                  placeholder="Хүйс"
                >
                  <option disabled value={""}>
                    Та сонгоно уу
                  </option>
                  <option value="female">Эмэгтэй</option>
                  <option value="male">Эрэгтэй</option>
                  <option value="other">Бусад</option>
                </Field>
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="gender" component="div" />
                </div>
              </div>
              <div className="w-full">
                <label className="mb-2 block">Таны нэр:</label>
                <Field
                  type="text"
                  name="firstname"
                  placeholder="Нэр"
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                />
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="firstname" component="div" />
                </div>
              </div>

              <div className="w-full">
                <label className="mb-2 block">Утасны дугаар:</label>
                <Field
                  type="text"
                  name="phone"
                  placeholder="Утас"
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                />
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="phone" component="div" />
                </div>
              </div>

              <div className="w-full">
                <label className="mb-2 block">Төрсөн огноо:</label>
                <Field
                  type="date"
                  name="birthday"
                  placeholder="Төрсөн огноо"
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                />
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="birthday" component="div" />
                </div>
              </div>

              <div className="w-full">
                <label className="mb-2 block">Мэйл хаяг:</label>
                <Field
                  type="email"
                  name="email"
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                  placeholder="И-мэйл"
                />
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="email" component="div" />
                </div>
              </div>
            </div>
            <h3 className="text-[21px] lg:text-[38px] font-bold my-10">
              Таны үзүүлэх үйлчилгээ
            </h3>
            <div>
              <FieldArray name="interested_in">
                {({ insert, remove, push }) => {
                  return (
                    <div>
                      {values.interested_in.length > 0 &&
                        values.interested_in.map((lang: any, index) => {
                          return (
                            <div
                              className="flex flex-col mb-10 border-b-2 pb-10 border-black/40 border-dashed"
                              key={index}
                            >
                              <div className="relative flex items-end mb-5">
                                <div className="flex-1">
                                  <label
                                    htmlFor={`interested_in.${index}.title`}
                                    className="mb-2 block"
                                  >
                                    Ажлын төрөл
                                  </label>
                                  <Field
                                    name={`interested_in.${index}.type`}
                                    placeholder={`Ажлын төрөл`}
                                    as="select"
                                    className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                                  >
                                    <option disabled value={""}>
                                      Та сонгоно уу
                                    </option>
                                    {(data || [])?.map((d: any) => (
                                      <option value={d?.id}>{d?.name}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name={`interested_in.${index}.type`}
                                    component="div"
                                  />
                                </div>
                                <div>
                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      className="rounded-[10px] flex items-center space-x-4 text-white p-[10px] h-[56px] justify-center"
                                      onClick={() => remove(index)}
                                    >
                                      <GrClose />
                                    </button>
                                  )}
                                </div>
                              </div>

                              <div
                                role="group"
                                aria-labelledby="my-radio-group"
                                className="flex space-x-4"
                              >
                                <label className="flex space-x-4 cursor-pointer">
                                  <Field
                                    type="radio"
                                    name={`interested_in.${index}.time`}
                                    value="part"
                                  />
                                  <span className="bg-[#F2F2F2] px-4 py-2 rounded">
                                    Гэрээт (Хагас цагаар, хуваарийн дагуу)
                                  </span>
                                </label>
                                <label className="flex space-x-4 cursor-pointer">
                                  <Field
                                    type="radio"
                                    name={`interested_in.${index}.time`}
                                    value="full"
                                  />
                                  <span className="bg-[#F2F2F2] px-4 py-2 rounded">
                                    Үндсэн (Бүтэн цагаар, тогтмол цагаар)
                                  </span>
                                </label>
                              </div>
                            </div>
                          );
                        })}

                      <button
                        type="button"
                        className="bg-black rounded-[10px] flex items-center space-x-4 text-white p-[10px]"
                        onClick={() => push({ title: "", description: "" })}
                      >
                        <BsFileEarmarkPlus /> <span>Нэмэх</span>
                      </button>
                    </div>
                  );
                }}
              </FieldArray>
            </div>

            <h3 className="text-[21px] lg:text-[38px] font-bold my-10">
              Нэмэлт мэдээлэл
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-10">
              <div className="w-full">
                <label className="mb-2 block">
                  Ажиллаж эхлэх боломжтой огноо:
                </label>
                <Field
                  type="date"
                  name="available_date"
                  placeholder="Ажиллаж эхлэх боломжтой огноо"
                  className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                />
                <div className="absolute h-[20px] text-red-500">
                  <ErrorMessage name="startDate" component="div" />
                </div>
              </div>
            </div>
            <div>
              <FieldArray name="language">
                {({ insert, remove, push }) => {
                  return (
                    <div>
                      {values.language.length > 0 &&
                        values.language.map((lang: any, index) => {
                          const stars = new Array(5).fill(0)?.map((_, i) => {
                            return (Number.parseInt(lang.rate, 10) || 0) > i
                              ? 1
                              : 0;
                          });

                          return (
                            <div
                              className="flex flex-col mb-10 border-b-2 pb-10 border-black/40 border-dashed"
                              key={index}
                            >
                              <div className="relative flex items-end mb-5">
                                <div className="flex-1">
                                  <label
                                    htmlFor={`language.${index}.title`}
                                    className="mb-2 block"
                                  >
                                    Хэлний мэдлэг {index + 1}:
                                  </label>
                                  <Field
                                    name={`language.${index}.lang`}
                                    placeholder={`Хэлний мэдлэг`}
                                    type="text"
                                    className="bg-gray-100 p-3 rounded-lg w-full h-[56px]"
                                  />
                                  <ErrorMessage
                                    name={`language.${index}.lang`}
                                    component="div"
                                  />
                                </div>
                                <div>
                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      className="rounded-[10px] flex items-center space-x-4 text-white p-[10px] h-[56px] justify-center"
                                      onClick={() => remove(index)}
                                    >
                                      <GrClose />
                                    </button>
                                  )}
                                </div>
                              </div>

                              <Field
                                name={`language.${index}.rate`}
                                type="hidden"
                              />
                              <div className="flex items-center space-x-4">
                                <span>Та чадвараа үнэлнэ үү </span>
                                <div className="flex items-center space-x-4">
                                  <span className="bg-[#F2F2F2] py-2 px-4 rounded-tl rounded-bl">
                                    Муу
                                  </span>
                                  <div className="flex text-3xl">
                                    {stars?.map((s, key) =>
                                      s ? (
                                        <AiFillStar
                                          key={`star-${index}-${key}`}
                                          onClick={() => {
                                            setFieldValue(
                                              `language.${index}.rate`,
                                              key + 1
                                            );
                                          }}
                                        />
                                      ) : (
                                        <AiOutlineStar
                                          key={`star-${index}-${key}`}
                                          onClick={() => {
                                            setFieldValue(
                                              `language.${index}.rate`,
                                              key + 1
                                            );
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                  <span className="bg-[#F2F2F2] py-2 px-4 rounded-tr rounded-br">
                                    Сайн
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <button
                        type="button"
                        className="bg-black rounded-[10px] flex items-center space-x-4 text-white p-[10px]"
                        onClick={() => push({ title: "", description: "" })}
                      >
                        <BsFileEarmarkPlus /> <span>Нэмэх</span>
                      </button>
                    </div>
                  );
                }}
              </FieldArray>
            </div>
            {/* <h3 className="text-[21px] lg:text-[38px] font-bold my-10">
              Таны үзүүлэх үйлчилгээ
            </h3> */}
            <div className="w-full mt-10">
              <label className="mb-2 block">Ажлын туршлага:</label>
              <Field
                as="textarea"
                name="work_experience"
                placeholder="Ажлын туршлага"
                className="bg-gray-100 p-3 rounded-lg w-full"
                rows={10}
              />
              <div className="absolute h-[20px] text-red-500">
                <ErrorMessage name="work_experience" component="div" />
              </div>
            </div>
            <div className="w-full mt-10">
              <label htmlFor="agreement" className="flex space-x-4">
                <Field
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  className="text-4xl"
                />
                <span>
                  Дээрх мэдээлэл нь үнэн зөв бөгөөд urtuu.com веб хуудасны
                  <a
                    href="/terms-of-service"
                    target={"_blank"}
                    className="underline"
                  >
                    {" "}
                    Нууцлалын Бодлог{" "}
                  </a>
                  ыг зөвшөөрч байна.
                </span>
              </label>

              <div className="absolute h-[20px] text-red-500">
                <ErrorMessage name="agreement" component="div" />
              </div>
            </div>
            <div className="text-center mb-20 mt-10">
              <button
                type="submit"
                disabled={isSubmitting}
                className="h-[85px] rounded-full border font-bold border-c1 text-c1 px-20 text-[18px] max-w-full w-[400px] mx-auto"
              >
                Илгээх
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default JobForm;
