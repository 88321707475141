import axios from "axios";

const apiWorker = {
  findType: async (params = {}) => {
    try {
      const res = await axios.get(
        "https://api.urtuu.com/noauth/api/worker/cv/type",
        {
          ...params,
        }
      );
      return { success: true, data: res?.data };
    } catch (e: any) {
      return { success: false, data: e?.response?.data };
    }
  },
  insert: async (data: any) => {
    const res = await axios.post(
      "https://api.urtuu.com/noauth/api/worker/cv/upload",
      {
        ...data,
      }
    );
    console.log("DATA DATA:", res?.data);
    return res?.data;
  },
};

export default apiWorker;
